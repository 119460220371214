<template>
	<section class="section wow">
		<div class="content">
			<Breadcrumb :page-name="'Список вакансий'" />
			<h1
				id="seo-title"
				class="seo-title"
			>
				Вакансии и стажировки для студентов и школьников
			</h1>
			<h3 class="page-title">
				Список вакансий и стажировок
			</h3>

			<div class="bar">
				<div
					v-if="vacancies || isLoading"
					class="search"
				>
					<div>
						<button class="search-button" />
						<input
							v-model="search"
							type="text"
							class="search-field"
							placeholder="Поиск по вакансиям"
							@input="searching"
						>
					</div>
				</div>
			</div>

			<div
				id="vacancies"
				class="vacancies-block"
			>
				<div class="grid">
					<div class="box">
						<BaseSidebar
							:cities="notNullSlug('cities').slice(0, filters.cities.localPage * 10)"
							:text-more-cities="textMoreNotNullSlug('cities')"
							is-show-search
							:value-cities="filters.cities.value"
							:companies="notNullSlug('companies').slice(0, filters.companies.localPage * 10)"
							:text-more-companies="textMoreNotNullSlug('companies')"
							:value-companies="filters.companies.value"
							:activities="notNullActivities"
							:value-activities="filters.activities.value"
							:specs="notNullSpecs"
							:value-specs="filters.specs.value"
							:types="notNullTypes"
							:value-types="filters.types.value"
							:employments="employments"
							:is-show="filters.isShow"
							:is-show-cities="filters.cities.isShow"
							:is-show-companies="filters.companies.isShow"
							:is-show-activities="filters.activities.isShow"
							:is-show-specs="filters.specs.isShow"
							:is-show-types="filters.types.isShow"
							:filters="filters"
							@searchSlug="searchSlug"
							@showMoreNotNullSlug="showMoreNotNullSlug"
							@changeParametersFilters="acceptFilter"
							@clearFilter="clearFilter"
							@showFilters="showFilters"
							@showFilterCities="showFilterCities"
							@showFilterCompanies="showFilterCompanies"
							@showFilterActivities="showFilterActivities"
							@showFilterSpecs="showFilterSpecs"
							@showFilterTypes="showFilterTypes"
						/>
					</div>
					<div class="box">
						<div
							v-if="!vacancies || !isLoading"
							class="preloader"
							style="margin-top: 100px"
						/>
						<template v-else>
							<div class="vacancies-number">
								Найдено вакансий: <span>{{ filters.amount }}</span>
							</div>
							<div class="vacancy-card-group">
								<BaseCardVacancies
									v-for="(vacancy, index) in vacancies"
									:key="index"
									:vacancy="vacancy"
									class="vacancy-card-item"
									:is-favorite="isFavorite(vacancy.id)"
									:is-favorite-vacancy="isFavorite(vacancy.id)"
								/>
							</div>
							<PagePagination
								v-if="pagination.ttl > 1"
								:pagination="pagination"
								:pages="pages"
								:is-show-total="isShowTotal"
								:is-show-next="isShowNext"
								@changePage="selectPage"
							/>
						</template>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@/assets/scripts/common';
import Breadcrumb from '@/components/Breadcrumb';
import PagePagination from '@/components/PagePagination';
import BaseCardVacancies from '@/components/BaseComponents/BaseCardVacancies';
import BaseSidebar from '@/components/BaseComponents/BaseSidebar/BaseSidebar';

export default {
    components: {
        Breadcrumb,
        PagePagination,
        BaseCardVacancies,
        BaseSidebar,
    },
    data: () => ({
        isLoading: false,
        search: '',
        filters: localStorage.getItem('getSelectedFiltersAllVacancies') ? JSON.parse(localStorage.getItem('getSelectedFiltersAllVacancies')) : {
            amount: undefined,
            isShow: false,
            cities: {
                search: '',
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            companies: {
                search: '',
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            activities: {
                value: [],
                isShow: false,
            },
            specs: {
                value: [],
                isShow: false,
            },
            types: {
                value: [],
                isShow: false,
            }
        },
        pagination: {
            init: 0,
            crnt: 1,
            ttl: undefined
        }
    }),
    computed: {
        ...mapGetters('tb', {
            vacancies: 'getAllVacancies',
            specs: 'getAllSpecs',
            activities: 'getAllActivities',
            types: 'getAllTypes'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            isRecruiter: 'isRecruiter',
            favoriteVacancies: 'favoriteVacancies'
        }),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        favoriteVacanciesId() {
            return this.favoriteVacancies.map((el) => {
                return el.id
            })
        },
        pages() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.filters.amount / 30) < 3 ? Math.ceil(this.filters.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.pagination.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.filters.amount) return Math.ceil(this.filters.amount / 30) > 3
            return false
        },
        isShowNext() {
            return (this.pagination.init !== this.pagination.ttl - 4) && this.isShowTotal
        },
        notNullActivities() {
            return this.activities && this.activities.filter(activity => !!activity.count).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
        },
        notNullSpecs() {
            return this.specs && this.specs.filter(spec => !!spec.count).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
        },
        notNullTypes() {
            return this.types && this.types.filter(type => !!type.count)
        }
    },
    async created() {
        if(this.isLogged) {
            this.$store.dispatch('user/getFavoriteVacancies')
        }
        let [cities, companies] = await Promise.all([
            this.getCities(),
            this.getCompanies(),
            this.getFilterVacancies(),
            !this.activities && this.getActivities(),
            !this.specs && this.getSpecs(),
            !this.types && this.getTypes()
        ])
        this.filters.cities.count = cities.length
        this.filters.cities.list = cities
        this.filters.companies.count = companies.length
        this.filters.companies.list = companies
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    if (callTime >= inputTime + 1000) {
                        this.getFilterVacancies()
                    }
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('tb', {
            getVacancies: 'getAllVacancies',
            getActivities: 'getAllCompanyActivity',
            getSpecs: 'getAllSpecialisations',
            getTypes: 'getAllTypesOfEmployment',
        }),
        ...mapActions('user', ['addFavoriteVacancy']),
        ...mapActions('cmn', {
            getCities: 'getVacancyCities',
            getCompanies: 'getAllCompanies'
        }),
        getFilterVacancies() {
            this.isLoading = false
            this.getVacancies({
                vacancy_name: this.search,
                cities: this.filters.cities.value,
                companies: this.filters.companies.value,
                field_activity_vacancy: this.filters.activities.value,
                specialisation_vacancy: this.filters.specs.value,
                page: this.pagination.crnt,
                type_employment: this.filters.types.value
            })
                .then(count => {
                    this.filters.amount = count
                    this.pagination.ttl = Math.ceil(count / 30)
                })
                .then(() => this.isLoading = true)
        },
        async clearFilter() {
            this.filters.isShow = false
            this.filters.cities.isShow = false
            this.filters.activities.isShow = false
            this.filters.specs.isShow = false
            this.filters.types.isShow = false
            localStorage.removeItem('getSelectedFiltersAllVacancies')
            let [cities, companies] = await Promise.all([this.getCities(), this.getCompanies()])
            this.search = ''
            this.filters = {
                amount: undefined,
                isShow: false,
                cities: {
                    search: '',
                    localPage: 1,
                    page: 1,
                    value: [],
                    count: cities.length,
                    list: cities,
                    isShow: false,
                },
                companies: {
                    search: '',
                    localPage: 1,
                    page: 1,
                    value: [],
                    count: companies.length,
                    list: companies,
                    isShow: false,
                },
                activities: {
                    value: [],
                    isShow: false,
                },
                specs: {
                    value: [],
                    isShow: false,
                },
                types: {
                    value: [],
                    isShow: false,
                }
            }
            this.getFilterVacancies()
        },
        acceptFilter(filters) {
            this.filters.cities.value = filters.localCities
            this.filters.companies.value = filters.localCompanies
            this.filters.activities.value = filters.localActivities
            this.filters.specs.value = filters.localSpecs
            this.filters.types.value = filters.localTypes
            this.pagination.crnt = 1
            localStorage.setItem('getSelectedFiltersAllVacancies', JSON.stringify(this.filters))
            this.getFilterVacancies()
        },
        showFilters(isShow) {
            this.filters.isShow = isShow
        },
        showFilterCities(isShow) {
            this.filters.cities.isShow = isShow
        },
        showFilterCompanies(isShow) {
            this.filters.companies.isShow = isShow
        },
        showFilterActivities(isShow) {
            this.filters.activities.isShow = isShow
        },
        showFilterSpecs(isShow) {
            this.filters.specs.isShow = isShow
        },
        showFilterTypes(isShow) {
            this.filters.types.isShow = isShow
        },
        selectPage(obj) {
            this.pagination.crnt = obj.item;
            this.getFilterVacancies()

            if (this.pagination.ttl === obj.item) {
                if (this.pagination.ttl >= 4) this.pagination.init = this.pagination.ttl - 4
                else this.pagination.init = 0
            } else if (obj.index === 0 && obj.item > 1) this.pagination.init--;
            else if (obj.index === 30 && obj.item < this.pagination.ttl - 1) this.pagination.init++;
            this.$scrollTo(document.querySelector('header.header-role'))
        },
        searching() {
            localStorage.setItem('getSelectedFilters', JSON.stringify(this.filters))
            this.pagination = {
                init: 0,
                crnt: 1,
                ttl: undefined
            }
            this.callDebounceWrapper()
        },
        notNullSlug(slug) {
            if (this.filters[slug].list.length) {
                return this.filters[slug].list.filter(item => !!item.count).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
            }
            return []
        },
        textMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.filters[slug].list.length && this.filters[slug].list !== undefined) {
                return `Показать еще ${this.filters[slug].list.length - this.filters[slug].localPage * 10 >= 10 ? 10 :
                    this.filters[slug].list.length - this.filters[slug].localPage * 10}`
            }
            return ''
        },
        async searchSlug(data) {
            let result = undefined
            if (data.slug === 'cities') {
                this.filters.cities.search = data.search;
                result = await this.getCities({
                    search: this.filters.cities.search ? this.filters.cities.search[0].toUpperCase() + this.filters.cities.search.slice(1) : ''
                })
            } else {
                this.filters.companies.search = data.search;
                result = await this.getCompanies({
                    search: this.filters.companies.search ? this.filters.companies.search[0].toUpperCase() + this.filters.companies.search.slice(1) : '',
                })
            }
            this.filters[data.slug].list = result
            this.filters[data.slug].count = result.length
            this.filters[data.slug].localPage = 1
            this.filters[data.slug].page = 1
        },
        showMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.filters[slug].list.length) {
                this.filters[slug].localPage++
            }
            else if (this.filters[slug].count > this.filters[slug].list.length) {
                slug === 'companies' && this.getCompanies({
                    company_name: this.filters[slug].search ? this.filters[slug].search[0].toUpperCase() + this.filters[slug].search.slice(1) : '',
                    page: ++this.filters[slug].page
                })
                    .then(result => {
                        this.filters[slug].list = this.filters[slug].list.concat(result.results)
                        this.filters[slug].localPage++
                    })

                slug === 'cities' && this.getCities({
                    search: this.filters[slug].search ? this.filters[slug].search[0].toUpperCase() + this.filters[slug].search.slice(1) : '',
                    page: ++this.filters[slug].page
                })
                    .then(result => {
                        this.filters[slug].list = this.filters[slug].list.concat(result.results)
                        this.filters[slug].localPage++
                    })
            }
        },
        collapseBlockSlug(slug) {
            this.filters[slug].isShow = !this.filters[slug].isShow
        },
        async eventYandexRespond(vacancy) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('click_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.$store.getters['user/isLoggedIn']) {
                this.checker = vacancy.id
                if (this.$store.state.user.responses) {
                    if (this.checkLimitResponses(this.$store.state.user.responses, vacancy.id)) this.modalOpen('reqVacancy', vacancy.id)
                    else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                    this.checker = undefined
                }
                else {
                    this.$store.dispatch('user/getAllResponses').then(result => {
                        if (this.checkLimitResponses(result, vacancy.id)) this.modalOpen('reqVacancy', vacancy.id)
                        else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        this.checker = undefined
                    })
                }
            } else {
                this.modalOpen('signIn')
            }
        },
        checkLimitResponses(responses, id) {
            // FIXME: заменить на значение из глобальных настроек сайта
            const limit = 1
            const totalResponse = responses.reduce((accum, current) => {
                if (current.vacancy.id === id) return accum += 1
                else return accum
            }, 0)

            return totalResponse < limit
        },
        isFavorite(id) {
            return this.favoriteVacanciesId.includes(id)
        },
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/vacancies";
</style>
